.parent-container{   
    max-width: 1300px !important;
    padding-top: 50px !important;
}

.parent-container .content-img {
  height: 130px;
  width: 130px;
  object-fit: cover;
}



.parent-container .image-icon {
  background: rgba(0, 0, 0, .81);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  opacity: 0;
  top: 0;
}


.drawer-header-div {
  display: flex;
  justify-content: center;
  padding-left: 10px;
}

.drawer-header-link {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 120px;
  padding: 0px;
}

.drawer-header-logo {
  height: 100%;
}

.topbar-main {
  background-color: white !important;
  color: gray !important;
  box-shadow: none !important;
  padding: 0px !important;
  min-height: 120px !important;
}
.toolbar-height{
  min-height: 120px !important;
}

p {
   font-family: 'Open Sans';
}