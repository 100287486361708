
.contact-container  {
    min-height: 63vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.contact-container .contact-box {
    background-image: url(https://i0.wp.com/midwest-group.com/wp-content/uploads/2017/10/yyc4.jpg?fit=694%2C520&ssl=1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 80px;
}

.contact-container .contact-box .contact-info {
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    font-size: 20pt;
    font-family: 'Open Sans', sans-serif;
    margin: 0px;
    padding: 0px;
}

.contact-container .contact-box .information {
    font-size: 14pt;
    font-family: 'Open Sans', sans-serif;
    color: #ffffff;
}

.contact-container .contact-box .seprator {
    background-color: #5f8c27;
    height: 3px;
    width: 14px;
    margin: 10px 0px;
}

.contact-container .contact-box .no-wrap {
    white-space: nowrap;
}