.scope_title_container {
    margin-bottom: 50px;
}

.scope_title {
    font-size: 55px;
    line-height: 60px;
    text-align: center;
}

.scope_bg_container {
    background-image: url('./../images/scope/Scope_Bg.png');
    background-repeat: repeat;
    background-position: 0 0;
    background-size: inherit;
    text-align: center;
    background-color: black;
    width: 100%;
}

.scope_bg_title_container {
    padding: 1px 0px 2px;
}

.scope_bg_title {
    font-family: 'Open Sans', sans-serif;
    list-style-type: none;
    text-transform: uppercase;
    color: #d6d6d6;
    text-align: center;
    font-size: 45px;
    line-height: 50px;
    font-weight: 400;
    margin: 0px !important;
    padding-top: 25px;
}

.scope_image_carousel_container {
    margin-top: 40px;
    padding: 0px 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scope_image_content_container {
    margin-top: 40px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.scope_image_card {
    position: relative;
    overflow: hidden;
    transition: 0.5s;
    height: 350px;
    width: 500px;
    cursor: pointer;
}

.scope_image_card:hover .content {
    opacity: 0;
}

.scope_image_card:hover .media {
    filter: none;
}

.media {
    filter: brightness(0.5);
    transition: 0.5s;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    color: white;
    opacity: 1;
    transition: opacity 0.5s;
}

.scope_image_title {
    color: #ffffff;
    font-size: 25px !important;
    font-weight: 600 !important;
}

.scope_image_description {
    color: #ffffff;
    font-size: 20px !important;
}

.scope-ul-margin {
    padding: 0px;
    margin: 0px;
    margin-bottom: 32px;
}

@media (max-width: 600px) {
    .scope_image_content_container {
        margin: 0px;
        width: 100%;
    }

    .scope_image_card {
        width: 100%;
    }

    .scope_bg_title {
        font-size: 46px;
        padding-top: 35px;
    }

}