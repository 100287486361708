.toggle,
[id^=drop] {
  display: none;
}

nav {
  background-color: #ffffff;
  /* position: fixed; */
  width: 100%;
  z-index: 10;
}


#logo {
  display: block;
  padding: 0px 0px 0px 50px;
  float: left;
  color: #FFF;
  cursor: pointer;
}

#logo img {
  width: 250px;
  height: auto;
}

/* nav {
  height: 120px;
} */

nav:after {
  content: "";
  display: table;
  clear: both;
}

nav ul {
  padding-left: 21rem;
  margin: 0;
  list-style: none;
  position: relative;
  margin-top: 50px;
  z-index: 999;
}

nav ul li {
  margin: 0px;
  display: inline-block;
}

nav a {
  display: block;
  padding: 14px 20px;
  color: #333;
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

nav ul li ul li:hover a {
  color: #5f8c27;
}

.main-menu:hover {
  color: #5f8c27;
}

nav ul ul {
  min-width: 174px;
  max-width: 170px;
  width: 156px;
  padding-left: 0;
  margin-top: 0;

}

nav ul ul li a {
  letter-spacing: 1px !important;
  padding: 8px 20px !important;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  border-style: solid;
  border-width: 1px;
  border-color: #5f8c27;
  background-color: #ffffff;
}

nav ul ul {
  display: none;
  position: absolute;
}

nav ul ul li {
  float: none;
  display: list-item;
  position: relative;

}

nav ul ul ul li {
  position: relative;
  top: -39px;
  left: 170px;
}

.nav_item {
  position: relative;
}

.nav_item.active::after {
  content: '';
  display: block;
  width: 15px;
  height: 2px;
  background: black;
  position: absolute;
  bottom: -4px;
  left: 20px;
}

.nav_item:hover::after{
    content: '';
    display: block;
    width: 15px;
    height: 2px;
    background: black;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

.main-menu{
  color: #1b1b1b;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 17px;
    cursor: pointer;
}

.main-menu-mobile{
  color: #1b1b1b !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px !important;
  letter-spacing: -0.5px !important;
  line-height: 25px;
    cursor: pointer !important;
}

.moblie_nav_item{
  color: #1b1b1b !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px !important;
  letter-spacing: -0.5px !important;
  line-height: 25px;
    cursor: pointer !important;
    text-decoration: none;

}

.moblie_nav_item-inactive{
  color: #1b1b1b !important;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 13px !important;
  letter-spacing: -0.5px !important;
  line-height: 25px;
    cursor: pointer !important;
    text-decoration: none;
}
.moblie_nav_item.active{
   color: #5f8c27 !important;
}

.menuIcon{
  color: #5f8c27 !important;
}
/* Media Queries
--------------------------------------------- */

@media all and (max-width : 991px) {
  .header-contact-detail {
    display: none !important;
  }

  .is-hidden-mobile {
    display: none !important;
  }


  [id^=drop]:checked+ul {
    position: relative;
    width: 100%;
    max-width: 100%;
    transition: all 2s ease-in-out;
  }

  .menu {
    width: 100%;
  }

  nav ul {
    float: left;
    text-align: left;
  padding-left: 0;
  position: absolute;
  z-index: 999;
  margin-top: 60px;
  }

  #logo {
    display: block;
    padding: 0;
    width: 100%;
    text-align: left;
    float: none;
    height: 23px;
  }


  #logo img {
    width: 150px;
    display: block;
    float: left;
    margin-top: 10px;
    margin-left: 8%;
    padding-bottom: 10px;
  }

  nav {
    /* height: 60px; */
    margin: 0;
    z-index: 99;
  }

  .nav_item.active::after {
    background: none;
  }
  
  .nav_item:hover::after{
     
      background: none;
     
  }

  /* Hide the navigation menu by default */
  /* Also hide the  */
  .toggle+a,
  .menu {
    display: none;
  }

  /* Stylinf the toggle lable */
  .toggle {
    font-size: 14px;
    font-weight: 400;
    display: block;
    background-color: #ffffff;
    color: #000;
    text-decoration: none;
    border: none;
  }

  .toggle-btn {
    transform: translate(-50%, 25%);
    left: 90%;
    top: 30px;
    position: absolute;
    background-color: transparent !important;
    color: #000;
  }

  .toggle:hover {
    color: #5f8c27;
  }

  /* Display Dropdown when clicked on Parent Lable */
  [id^=drop]:checked+ul {
    display: block;
  }

  /* Change menu item's width to 100% */
  nav ul li {
    display: block;
    width: 100%;
  }

  nav ul ul .toggle,
  nav ul ul a {
    padding: 0 40px;
  }

  nav a {
    display: inline-block;
    padding: 12px 20px;
    /* color: #fff; */
    color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    letter-spacing: 0;
  }

  nav a:hover,
  nav ul ul ul a:hover {
    color: #5f8c27;
  }

  nav ul li ul li .toggle,
  nav ul ul a,
  nav ul ul ul a {
    color: #FFF;
    /* font-size: 0.8em; */
    text-transform: none;
    background: transparent;
  }

  nav ul ul ul li .toggle:hover {
    color: #FFF;
    font-size: 0.9em;
    text-transform: none;

  }

  nav ul ul li a {
    text-transform: none;
    border: none;
    font-size: 14px;
  }

  /* Hide Dropdowns by Default */
  nav ul ul {
    float: none;
    position: static;
    color: #ffffff;
    /* has to be the same number as the "line-height" of "nav a" */
  }

  /* Hide menus on hover */
  nav ul ul li:hover>ul,
  nav ul li:hover>ul {
    display: none;
  }

  /* Fisrt Tier Dropdown */
  nav ul ul li {
    display: block;
    width: 100%;
    background-color: #fff;
    border: none;
    border-top: 1px solid #eaeaea
  }

  nav ul ul ul li {
    position: static;
    background: transparent !important;
    background-color: rgba(0, 0, 0, 0.7) !important;

    /* has to be the same number as the "width" of "nav ul ul li" */

  }

  /* 
  nav ul ul ul li .toggle{
    background: transparent;
    background-color: rgba(0, 0, 0, 0.7);
  } */

  nav ul li {
    display: block;
    width: 100%;
    border-bottom: 1px solid #eaeaea
  }

  .sub-menu-hover {
    /* background-color: rgba(0, 0, 0, 0.7) !important; */
    background-color: rgba(0, 0, 0, 0.1)
  }

}


.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  letter-spacing: 1px !important;
  padding: 0px 7px !important;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  border-style: solid;
  border-width: 1px;
  border-color: #5f8c27;
  background-color: #ffffff;
}

.dropdown-content a:hover {background-color: white;}

.dropdown:hover .dropdown-content {display: block;}


@media all and (min-width : 1024px) {
  .is-hidden-desktop {
    display: none !important;
  }

  nav ul ul li:hover .sub-menu-hover {
    display: inherit;
    /* background-color: rgba(0, 0, 0, 0.7); */
  }

  nav ul li:hover .menu {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    display: inherit;
    background-color: #eeeeee;
  }
}