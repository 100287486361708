.main-content {
  font-family: Open Sans;
  text-align: center;
}

.related_project_container{
 padding: 80px 00px;
}

.related_project_title{
  font-family: 'Open Sans', sans-serif !important;
  font-size: 19px !important;
  line-height: 1.375em;
  font-weight: 400 !important;
  padding: 85px 0 55px;
}

.releted-project-sub-title {
  font-family: Open Sans !important;
  color: #000000;
  letter-spacing: 1px !important;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600 !important;
  font-size: 15px !important;
  cursor: pointer;
}

.releted-project-sub-title:hover {
  color: rgba(95, 140, 39, 1);
}

.project-card {
  position: relative;
  overflow: hidden;
  height: 50%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}

.releted-project-sub-title-second {
  margin: 0;
  font-size: 12px !important;
  color: #5f8c27 !important;
  line-height: 1.5em;
  text-transform: none;
  font-weight: 300;
  text-align: center;
  font-family: Open Sans !important;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card:hover .card-overlay {
  opacity: 1;
}

.plus-icon {
  font-size: 48px;
  font-weight: bold;
}

.related_project_image{
  transition: transform 0.3s ease;
}

.image_holder {
  position: relative;
  overflow: hidden;
  transition: all .7s ease-out;
}


.image_holder:hover .related_project_image {
  -webkit-transform: scale(1.1);
   transform: scale(1.1);
}

.text-holder .text-outer .text-inner .view-text:hover{
  background-color: #5f8c27 !important;
  color: #ffffff !important;
  border-color: #5f8c27 !important;
}

.image_holder:hover .text-holder  {
   opacity: 1;
}

.image_holder .text-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: visible;
  overflow: hidden;
  width: 100%;
  z-index: 200;
  height: 100% !important;
  opacity: 0;
  background: rgba(21, 21, 21, .78);
}

.image_holder .text-holder .text-outer {
  display: table;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.image_holder .text-holder .text-outer .text-inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.image_holder .text-holder .text-outer .text-inner .view-text {
  position: relative;
  display: inline-block;
  margin: 0 0 5px;
  -webkit-backface-visibility: hidden;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0 17px;
  color: #000000;
  background-color: #ffffff;
  text-decoration: none;
  font-weight: 700;
}