.post-related-text {
    font-family: inherit;
    font-size: 12px;
    font-weight: 700;
    line-height: 30px;
    font-style: normal;
    letter-spacing: 1px;
    border-top: 1px solid #E6E6E6;
    padding-top: 15px;
}

.related_title {
    margin: 0px !important;
    padding: 0px !important;
    font-size: inherit;
    font-weight: 400;
    -moz-opacity: 1;
    opacity: 1;
    text-decoration: none;
    cursor: pointer;
    font-family: 'Open Sans';
}

.related_title:hover {
    text-decoration: underline;
    color: #5f8c27;
}

.related_date {
    font-family: 'Open Sans';
    margin: 0px !important;
    padding: 0px !important;
    font-size: 14px;
    line-height: 20px;
    color: #979797;
}

.protfolio_btn {
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    color: #b4b4b4;
    cursor: pointer;
    white-space: nowrap;
    border: 2px solid #e5e5e5;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-shadow: none;
    transition: all 0.3s ease;
}

.protfolio_btn:hover {
    background-color: #e5e5e5;
    color: black;
}

.protfolio_app_icon_navigation {
    font-size: 30px;
    color: #E5E5E5;
    cursor: pointer;
}

.protfolio_app_icon_navigation:hover {
    color: #b4b4b4;
}