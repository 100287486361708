.image_preview_container {
    position: relative;
    width: 100%;
    height: 100%;
}


.preview-carousel-image {
    width: 100%;
    height: auto;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.preview-carousel-image.fade {
    opacity: 0;
}

.image_preview_container{
    height: 719px;
    width: 900px;
}

.MuiModal-root:focus {
    outline: none;
}

.preview_arrow{
    cursor: pointer;
}

.preview_image_index{
    font-family: Raleway, sans-serif;
    font-size: 15px;
    color: #fff;
    font-weight: 600;
}

.image-preview-box{
    height: 90vh;
    }