.landing-container {
  position: relative;
  width: 100%;
  height: 700px;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-in-text {
  opacity: 0;
  position: relative;
  animation: slideIn 1s ease-out forwards;
  margin: 0px;
}

.centered-text-home {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #000000;
  text-align: center;
  font-size: 21px;
  width: 100%;
}

.centered-text-home .homebutton {
  margin-top: 40px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  padding: 10px 30px !important;
  font-size: 1.5rem !important;
  border-radius: 0px !important;
}

.centered-text-home .homebutton:hover {
  cursor: pointer;
  background-color: #ffffff;
  color: #000 !important;
}

.landing-section {
  margin-top: 100px;
  text-align: center;
}

.landing-section h1 {
  text-align: center;
  color: #333333;
  line-height: 1.4em;
  font-size: 2.5em;
  letter-spacing: 0.1em;
  font-weight: 400;
}

.landing-section .decoration {
  line-height: 0em;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}


.landing-section .decoration .decoration-inside {
  height: 1px;
  display: inline-block;
  border-top: 1px solid #333333;
  width: 100%;
  max-width: 10%;
}

.h2-heading {
  color: #666666;
  font-weight: 600;
}

.meet-section {
  margin-top: 4rem;
  text-align: center;
}

.meet-section h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
}

.meet-section .about-button {
  background-color: #5f8c27;
  border-radius: 0;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  padding-block: 25px;
  padding-inline: 14rem;
  font-family: 'Open Sans', sans-serif;
  text-transform: none;
}

.meet-section .about-button:hover {
  background-color: #000000;
}

.meet-section .meet-paragraph p {
  margin-top: 0;
  font-size: 17px;
  font-weight: 400;
}

.aboutparagraph {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #5f8c27;
  line-height: 27px;
  text-align: center;
  margin-top: 30px;
}

/* Keyframes for slide-in effect */
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    /* Start off screen to the left */
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    /* End at original position */
    opacity: 1;
  }
}