/* src/ImageCarousel.css */
.carousel-container {
    text-align: center;
    position: relative;
    width: 100%;
}

.image-wrapper {
    overflow: hidden;
    position: relative;
}

.carousel-image {
    width: 100%;
    height: auto;
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.carousel-image.fade {
    opacity: 0;
    /* Fade out */
}

.circles {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 100%;
}

.circle {
    width: 9px;
    height: 9px;
    margin:  0px 5px;
    border-radius: 50%;
    background-color: lightgray;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.circle.active {
    background-color: black;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 26px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.arrow.left {
    left: 10px;
}

.arrow.right {
    right: 10px;
}

.arrow:hover {
    color: #fcb900;
}