body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navigation-link {
  color: #5F8C27;
  cursor: pointer;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 27px;
  font-style: normal;
  text-decoration: none;
}

.portfolio_url_text{
  color: #5F8C27;
  cursor: pointer;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 27px;
  font-style: normal;
}