.main-left-div {
  padding-right: 4rem;
}

.separator{
  background-color: #5f8c27;
  height: 3px;
  width: 20%; 
  display: block;
  margin: 10px auto 20px; 
  margin-left: 0;
  margin-right: 0;
}


/* .gallary-holder:hover .parent-container .content-img {

} */

.gallary-holder {
  position: relative !important;
  overflow: hidden; /* Ensures the image doesn't overflow outside the container */
}

.gallary-holder:hover .image-icon {
  opacity: 1;
  cursor: pointer;
}



.heading-info{
    font-family: 'Open Sans', sans-serif;
    font-size: 55px;
    line-height: 60px;
    margin: 0px;
    margin-bottom: 20px;
}

.sub-heading-info{
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 1.571428571428571em;
    margin: 0px;
}


.main-left-div .description{
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    line-height: 27px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    text-align: start;
}

/* .portfolio_url_text{
  color: #5F8C27;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  line-height: 27px;
  font-style: normal;
} */