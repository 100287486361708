@media all and (max-width: 1600px) {}

/*--------------------------------------------*/
/* Mini Laptop with All Laptop (1400px) View*/
/*--------------------------------------------*/
@media all and (max-width: 1400px) {}

/*--------------------------------------------*/
/* Mini Laptop with All Laptop (1299px) View*/
/*--------------------------------------------*/
@media all and (max-width: 1299px) {}

/*--------------------------------------------*/
/* Mini Laptop with All Laptop (1299px) View*/
/*--------------------------------------------*/
@media all and (max-width: 1199px) {}

/*--------------------------------------------*/
/* Mini Laptop with All Tab Devices (1024px) View*/
/*--------------------------------------------*/
@media all and (max-width: 1024px) {}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px) {

     .home-section {
          height: 800px !important;
     }

     .home-section img {
          height: 100% !important;
     }

     .centered-text-home p {
          font-size: 0.9rem;
     }

     .landing-section h1 {
          font-size: 2em;
     }

     .landing-section p {
          font-size: 1em;
          margin-inline: 20px;
     }


}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (891px) View*/
/*--------------------------------------------*/
@media all and (max-width: 891px) {}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (767px) View*/
/*--------------------------------------------*/
@media all and (max-width: 767px) {
     .parent-container {
          padding: 50px 5px 0px 5px !important;
     }

     .contact-container,
     .safety-content {
          margin: 2rem;
     }

     .project-list {
          margin-top: 0px !important;
     }

     .parent-container .content-img {
          width: 100% !important;
          height: 300px !important;
     }

     .main-left-div {
          padding-right: 0rem !important;
     }

     .parent-container .image-icon {
          height: 100%;
     }

     .image-list {
          display: block !important;
     }

     .parent-container .content-img {
          width: 100% !important;
          height: 300px !important;
     }

     .contact-container .contact-box {
          padding: 40px 20px !important;
     }

     .image-preview-box {
          height: auto !important;
     }

     .drawer-header-div {
          width: 100%;
          padding-left: 0px !important;
     }

     .navlist-padding {
          margin: 0px 15px !important;
     }

     .drawer-header-link {
          height: 100px !important;
     }

     .meet-section .about-button {
          padding-inline: 9rem !important;
     }

     .centered-text-home {
           padding: 0px 25px;
           font-size: 16px !important;
           top: 20% !important;
     }
}

/*--------------------------------------------*/
/* Mini Tab with All Mobile Devices (600px) View*/
/*--------------------------------------------*/
@media all and (max-width: 600px) {}

/*--------------------------------------------*/
/* Mini Mobile with All Mobile Devices (480px) View*/
/*--------------------------------------------*/
@media all and (max-width: 480px) {}

/*--------------------------------------------*/
/* Mini Mobile with All Mobile Devices (360px) View*/
/*--------------------------------------------*/
@media all and (max-width: 360px) {}

@media all and (max-width: 340px) {}

/*--------------------------------------------*/
/* Mini Mobile with All Mobile Devices (320px) View*/
/*--------------------------------------------*/
@media all and (max-width: 320px) {}


@media all and (max-width: 767px) {

     .contact-container,
     .safety-content {
          margin-top: 2rem;
     }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
     .drawer-header-link {
          height: 70px !important;
     }
}