.footer_top_holder{
    background-color: #000000;
    margin-top: 10px;
}

.footer_top{
    text-align: center;
    padding: 72px 0 52px;
}

.container{
    padding: 0;
    width: 100%;
    z-index: 100;
    position: relative;

}

.container_inner{
    position: relative;
    margin: 0 auto;
}

.footer_col1{
    text-align: center;
}

.footer_top h5 {
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0 22px;
    letter-spacing: 1px;
    font-weight: 600;
}

.footer_top p, .footer_top span {
    color: #818181;
    word-wrap: break-word;
}

.footer_top .textwidget {
    color: #818181!important;
    word-wrap: break-word;
}

.footericons a{
    color: #ffffff!important;
}
.footericons a:hover{
    color: #5f8c27!important;
}

@media only screen and (min-width: 1400px) {
    .container_inner{
        width: 1300px;
    }
}