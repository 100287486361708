.main-content {
  font-weight: bold;
  font-family: Open Sans;
}
.sub-title {
    font-family: Open Sans !important;
    color: #000000;
    padding: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 15px !important;
}

.project-card {
  position: relative;
  overflow: hidden;
  height: 550px;
}
.project-card:hover {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-card:hover .card-overlay {
  opacity: 1;
}

.plus-icon {
  font-size: 48px;
  font-weight: bold;
}


.project-card .add-icon {
  font-size: 100px;
  font-weight: 100;
  transition: transform 0.5s ease, opacity 0.5s ease;
  transform: translate(100px, 100px);
  opacity: 0;
  font-family: Raleway;
}
 
.project-card:hover .add-icon {
  transform: translate(0, 0);
  opacity: 1;
}
 
.css-1dbpucb-MuiTooltip-tooltip {
  background-color: #ffffff!important;
  color: #000!important;
}