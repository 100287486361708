/* .safety-content {
     padding-top: 5rem;
} */
.safety-content .safety-container {
    max-width: 1300px;
    padding-left: 0px;
    padding-right: 0px;
}
.safety-content .safety-header .safety-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 45px;
    line-height: 60px;
    margin-bottom: 40px;
    margin-top: 0px;
}

.safety-content .safety-header .safety-info {
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
}
.safety-content .safety-header .info  {
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
    text-transform: none;
    margin: 0px;
}
.safety-content .safety-hats {
    display: inline-block;
    position: relative;
    margin: 0;
    width: 100%;
   margin: 70px 0px;
}
.safety-content .safety-hats .safety-img {
    display: block;
    position: relative;
    width: 100%;
    z-index: 10;
}

.safety-content .safety-hats .shader {
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .4s ease-in-out;
    z-index: 20;
    -webkit-transition: opacity .4s ease-in-out;
    -moz-transition: opacity .4s ease-in-out;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.safety-content-new .safety-header .safety-heading {
    font-family: 'Open Sans', sans-serif;
    font-size: 55px;
    line-height: 60px;
    margin-bottom: 40px;
    margin-top: 0px;
}